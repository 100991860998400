import dayjs from 'dayjs';

export default {

	dateFormatUs(date) {
		var today = new Date(date);
		var year = today.getFullYear();
		var mes = today.getMonth()+1;
		var dia = today.getDate()+1;
		var fecha =year+"-"+mes+"-"+dia;
		return dayjs(fecha).format('YYYY-MM-DD')
	},

	dateFormatEs(date) {
		var today = new Date(date);
		var year = today.getFullYear();
		var mes = today.getMonth()+1;
		var dia = today.getDate()+1;
		var fecha =year+"-"+mes+"-"+dia;
		return dayjs(fecha).format('DD/MM/YYYY')
	},

	dateFormat(date) {
		return new Date(date).toLocaleDateString('en-EN', {
			year : 'numeric',
			month : 'numeric',
			day : 'numeric',
		}).split(' ').join('/');
	},


}
